.progressContainer {
    position: relative; /* Contient tous les éléments positionnés */
    margin-top: 80px;
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }
  
  .progress {
    width: 80%;
    max-width: 600px;
    height: 12px;
    margin: 0 auto;
    background: #f1f1f1;
    border-radius: 15px; /* Rayon pour arrondir les coins */
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Ombre subtile */
  }
  
  .progressBar {
    height: 100%;
    background: linear-gradient(to right, #4eb933, #2d9a1a);
    transition: width 0.4s ease; /* Animation fluide */
    z-index: 1; /* Derrière le vélo */
    border-radius: 15px; /* Rayon identique pour un effet homogène */
  }
  
  .bikeIcon {
    position: absolute;
    top: -25px; /* Ajustement pour positionner au-dessus de la barre */
    font-size: 40px; /* Taille de l'icône vélo */
    left: 0;
    transform: translate(-50%, 0) scaleX(-1); /* Centre et inverse le vélo */
    transition: left 0.4s ease; /* Animation fluide */
    z-index: 2; /* Devant la barre */
  }
  
  .stepIndicator {
    font-size: 14px;
    color: #555;
    margin-top: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

