.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popupContent {
    background: white;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: left;
    width: 90%;
    max-width: 500px;
  }
  
  .popupTitle {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .popupList {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
  }
  
  .popupList li {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .popupActions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .validateBtn,
  .closeBtn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .validateBtn {
    background: #4caf50;
    color: white;
  }
  
  .validateBtn:hover {
    background: #45a049;
  }
  
  .closeBtn {
    background: #f44336;
    color: white;
  }
  
  .closeBtn:hover {
    background: #e53935;
  }
  