
/* Styles généraux */
* {
    box-sizing: border-box;
}

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto; /* Active le défilement global sur la page */
}

.content-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
}

.content-container {
    margin-top: 60px;
    padding: 20px;
    min-height: calc(100vh - 60px);
    overflow-y: auto;
}

.text-success {
    color: #4eb933;
}

.text-danger {
    color: #d9534f;
}