/* HistoriqueTransactions.module.css */

.historiqueContainer {
    margin: 40px auto; /* Ajoute de l'espace autour du conteneur */
    padding: 20px;
    max-width: 1600px; 
    margin-top: 50px;
}

.transactionCount {
    margin-left: 15px;
    font-size: 1.5rem;
    background-color: #6d6d6d;
    color: #fff;
    padding: 5px 18px;
    border-radius: 30px;
}

.tableContainer {
    margin-top: 20px; /* Espace entre le titre et la table */
}
