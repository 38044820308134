/* Style général de la navbar */
.navbar {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1020;
}

/* Style du logo */
.logo {
    width: 60px; /* Taille du logo */
    height: auto;
    margin-right: 30px; /* Espace entre le logo et le reste du contenu */
}

.back-button {
    display: flex; /* Active la disposition en ligne pour l'icône et le texte */
    align-items: center; /* Aligne verticalement l'icône et le texte */
    gap: 8px; /* Ajoute un écart de 8px entre l'icône et le texte */
    border: none; /* Supprime les bordures */
    background: none; /* Supprime le fond */
    color: #333; /* Couleur du texte */
    font-size: 16px; /* Taille du texte */
    cursor: pointer; /* Change le curseur pour indiquer un clic */
  }
  

/* Contenu de la navbar aligné à droite */
.navbar-content {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto; /* Aligne tout le contenu à droite */
}

.language-button {
    border: none;
    background: none;
    cursor: pointer;
}

.flag-icon {
    width: 30px;
    height: 20px;
}

/* Dropdown pour la sélection de langue */
.dropdown-menu {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1050;
}

.language-selector {
    position: relative;
    display: flex;
    align-items: center;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: black;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.dropdown-flag-icon {
    width: 20px;
    height: 15px;
    margin-right: 5px;
}

/* Informations utilisateur */
.user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 150px;
    padding: 5px 0;
    z-index: 10;
}

.user-dropdown-item {
    width: 100%;
    padding: 10px 15px;
    background: none;
    border: none;
    text-align: left;
    font-size: 14px;
    color: #333;
    cursor: pointer;
}

.user-dropdown-item:hover {
    background-color: #f0f0f0;
    color: #4eb933;
}

.user-name {
    font-size: 0.9rem;
    color: #333;
    white-space: nowrap;
}

.user-profile {
    width: 35px;
    height: 35px;
    border-radius: 30px;
    object-fit: cover;
    cursor: pointer;
}
