.contentContainer {
    margin: 60px auto;
    padding: 40px;
    max-width: 1200px;
    min-height: calc(100vh - 120px);
    border-radius: 12px;
    overflow-y: auto;
    background-color: #f8f9fc;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap; /* Permet de passer à la ligne si nécessaire */
    justify-content: center; /* Centre les cartes horizontalement */
    gap: 25px; /* Espacement entre les cartes */
}

.assuranceCard {
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

.assuranceCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardHeader {
    background-color: #4eb933;
    color: white;
    padding: 15px;
    font-size: 1.2rem;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.assuranceDetails {
    text-align: left;
    margin-bottom: 20px;
}

.assuranceValue {
    font-size: 1.4rem;
    font-weight: bold;
    color: #4eb933;
    margin-bottom: 10px;
}

.guaranteesTable {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
    font-size: 0.9rem;
}

.guaranteesTable th, .guaranteesTable td {
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
}

.guaranteesTable th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.infoIcon {
    color: #4eb933;
    cursor: pointer;
    font-size: 1.5rem;
}

.infoIcon:hover {
    color: #368124;
}

.pdfLink {
    color: #4eb933;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.pdfLink:hover {
    color: #368124;
    text-decoration: underline;
}

.nextStepBtn {
    background-color: #4eb933;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.nextStepBtn:hover {
    background-color: #3aa72a;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.assuranceCount {
    margin-left: 10px;
    font-size: 1.4rem;
    background-color: #6d6d6d;
    color: #fff;
    padding: 5px 15px;
    border-radius: 20px;
}
