/* General container */
.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7;
    font-family: 'Arial', sans-serif; /* Use a more modern font */
    padding: 20px;
}

/* Spinner animation */
.loadingSpinner {
    border: 12px solid #f3f3f3;
    border-top: 12px solid #4eb933;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



/* Buttons container */
.buttonContainer {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    gap: 1.5rem; /* Slightly increased gap for better spacing */
    margin-top: 2rem;
    width: 100%; /* Ensure buttons stretch across the container */
}

/* Button styles */
.completeTransactionButton, .cancelButton {
    padding: 0.9rem 1.8rem;
    font-size: 18px;
    color: #fff;
    background-color: #c0c0c0;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 180px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.completeTransactionButton:hover, .cancelButton:hover {
    background-color: #3a9a28;
    transform: translateY(-2px); /* Slight raise effect on hover */
}

.cancelButton {
    background-color: #d3d3d3;
    width: 180px;
    margin-top: 1rem; /* Space between main buttons and the cancel button */
}

.cancelButton:hover {
    background-color: #4eb933;
}

/* Image container */
.imageContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 50%;
}

.loadingImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Status message */
.statusMessage {
    flex: 1;
    padding: 2rem;
    max-width: 40%;
}

.statusMessage h2 {
    font-size: 30px;
    margin-bottom: 1rem;
    color: #333;
}

.statusMessage p {
    font-size: 20px;
    margin-top: 1rem;
    color: #555;
}

/* Overlay text on image */
.statusMessageOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    border-radius: 8px;
}


.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popupContent {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .popupButton {
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    font-size: 16px;
    color: #fff;
    background-color: #4eb933;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popupButton:hover {
    background-color: #3a9a28;
  }

  .disabledButton {
    background-color: #e0e0e0; /* Gray background */
    cursor: not-allowed; /* Disable the hover effect */
    pointer-events: none; /* Disable click events */
  }
  