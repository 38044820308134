/* App.css */
body{
 background-color: #f8f9fc; 
}
.wrapper {
    display: flex;
  }
  
  .content-wrapper {
    margin-top: 70px; /* Height of the navbar */
    margin-left: 200px; /* Width of the sidebar */
    padding: 20px;
    flex-grow: 1;
  }
  
  .navbar {
    z-index: 1020;
  }
  
  .sidebar {
    z-index: 1030;
    width: 250px; /* Ensure the width of the sidebar is fixed */
  }
  