/* Styles pour le modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modalBody {
  margin-top: 20px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

/* Toggle Switch */
.assuranceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleSwitch {
  position: relative;
  width: 50px;
  height: 24px;
}

.toggleSwitch input[type="checkbox"] {
  display: none;
}

.switchLabel {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.switchLabel:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: white;
  top: 1px;
  left: 1px;
  transition: 0.2s;
}

.toggleSwitch input[type="checkbox"]:checked + .switchLabel {
  background-color: #4caf50;
}

.toggleSwitch input[type="checkbox"]:checked + .switchLabel:before {
  transform: translateX(26px);
}
