html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden; /* Supprime la barre de défilement verticale */
    box-sizing: border-box;
  }
  
.contentContainer {
    margin-top: 5px; /* Réduction de l'espace entre la barre de progression et le contenu */
    padding: 20px;
    min-height: calc(100vh - 160px);
    width: 100%;
    max-width: 1300px;
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

/* Barre de progression */
.progressContainer {
    margin-top: 10px; /* Réduit l'espace entre la barre de progression et le haut */
    text-align: center;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 10;
    padding: 10px 0;
}

.progress {
    background-color: #e0e0e0;
    border-radius: 10px;
    height: 8px;
    width: 50%; /* Largeur réduite pour des écrans larges */
    max-width: 500px;
    margin: 0 auto 20px auto;
}

.progressBar {
    background-color: #4eb933;
    height: 100%;
    border-radius: 10px;
    transition: width 0.4s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
/* Titre principal */
.titleHeading {
    color: black;
    font-weight: 500;
    margin-top: 5px; /* Réduction de l'espace au-dessus */
    margin-bottom: 10px; /* Réduction de l'espace sous le titre */
}


/* Styles de carte */
.cardTitle {
    font-weight: bold;
    color: black;
    font-size: 1.2rem;
    margin: 0;
}

.cardBody {
    padding: 4px 8px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardsAndAdvantagesContainer {
    display: flex;
    justify-content: flex-start; /* Aligne les carts sur la gauche */
    align-items: flex-start; /* Aligne verticalement en haut */
    gap: 10px; /* Réduit l'espacement entre les deux carts */
    margin-top: 20px;
    width: 100%;
}

  .selectedCardWrapper {
    flex: 1;
    max-width: 50%; /* Occupe 50% de l'espace disponible */
  }

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start; /* Aligne les cartes en haut */
    gap: 20px;
    position: relative; /* Nécessaire pour la carte sélectionnée */
}
  .defaultCard {
    position: relative;
    width: 400px;
    transition: transform 0.6s ease, opacity 0.6s ease;
    z-index: 1;
}

input[type="radio"] {
    appearance: none; /* Supprime l'apparence par défaut des boutons radio */
    width: 16px; /* Largeur du bouton */
    height: 16px; /* Hauteur du bouton */
    border: 2px solid #4eb933; /* Couleur de la bordure */
    border-radius: 50%; /* Forme circulaire */
    outline: none; /* Supprime le contour par défaut */
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    margin-top: 2px; /* Ajuste verticalement le bouton radio */
  }
  
  /* État au survol */
  input[type="radio"]:hover {
    border-color: #3aa72a; /* Change légèrement la couleur au survol */
  }
  
  /* État sélectionné */
  input[type="radio"]:checked {
    background-color: #4eb933; /* Couleur de fond lorsque le bouton est sélectionné */
    border-color: #4eb933; /* Couleur de la bordure */
    box-shadow: 0 0 4px rgba(78, 185, 51, 0.5); /* Effet d'ombre subtile */
  }
  
  
.selectedCard {
    width: 100%; /* Laissez Bootstrap gérer la largeur */
    margin: 0; /* Supprimez les marges pour éviter l'espacement */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
.hiddenCard {
    opacity: 0;
    pointer-events: none; /* Désactive les interactions */
    transition: opacity 0.4s ease;
}

.advantagesContainer {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .advantagesTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4eb933;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .pdfLink {
    display: inline-block;
    margin-top: 10px;
    color: #4eb933;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .pdfLink:hover {
    color: #368124;
    text-decoration: underline;
  }
  
  
  

/* Effet au survol pour les cartes */
.hoverCard:hover {
    border: 2px solid #4eb933;
    background-color: #e9f9e9;
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.hoverCard {
    padding: 0;
    margin-bottom: 16px;
    border-radius: 8px;
    height: 140px;
    width: 100%;
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
}

.hoverCard img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 0 auto;
    transition: all 0.3s ease;
}

/* Style des champs de sélection */
.selectStyle {
    height: 45px;
    font-size: 1rem;
    padding: 8px 12px;
    color: #333;
    border: 2px solid #4eb933;
    border-radius: 5px;
    background-color: white;
    width: 100%; /* Champ en pleine largeur pour un alignement cohérent */
}

.selectStyle:hover,
.selectStyle:focus {
    border-color: #4eb933;
    box-shadow: 0 0 8px rgba(78, 185, 51, 0.3);
    outline: none;
}

.formGroup {
    margin-bottom: 20px; /* Ajoute de l'espace entre chaque champ */
    width: 100%;
}

/* Label de groupe de formulaire */
.formGroup label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.nextStepBtn {
    background-color: #4eb933;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px; /* Réduisez cette marge si nécessaire */
}


.nextStepBtn:hover {
    background-color: #3aa72a;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.recapCardContainer {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 650px;
    margin: 0 auto;
    border: 1px solid #ddd;
}

.recapSection {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
}

.recapSection:last-child {
    border-bottom: none;
}

.recapSection h4 {
    color: #4eb933;
    font-weight: bold;
    margin-bottom: 10px;
}

.icon {
    color: black; /* Définit la couleur de l'icône en noir */
    margin-right: 10px; /* Ajoute un espace entre l'icône et le titre */
}

.alignGuarantee {
    display: flex;
    align-items: center;
    gap: 10px; /* Espacement entre le ? et le label */
  }
  
  .advantagesCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: auto;
  }
  
  .assuranceCard {
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centre le contenu horizontalement */
    gap: 20px; /* Espacement entre les colonnes */
}

.cardHeader {
    background-color: #4eb933;
    color: white;
    padding: 15px;
    font-size: 1.2rem;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.assuranceDetails {
    text-align: left;
    margin-bottom: 20px;
}

.assuranceValue {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #4eb933;
}

.guaranteesTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 0.9rem; /* Réduction de la taille de police */
}

.guaranteesTable th, .guaranteesTable td {
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
}

.guaranteesTable th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.checkIcon, .crossIcon {
    display: inline-block;
    font-size: 1.5rem; /* Ensures consistent size */
    vertical-align: middle; /* Aligns with text */
    width: 28px; /* Explicit width */
    height: 24px; /* Explicit height */
    margin: 0; /* Removes any extra margin */
}


.infoIcon {
    color: #4eb933;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 1.8rem;
    margin-right: 10px;
}

.infoIcon:hover {
    color: #368124;
}

.guaranteeLabel {
    color: black;
    font-size: 1rem;
    margin-left: 10px;
}

.pdfLink {
    color: #4eb933;
    text-decoration: none;
    font-weight: bold;
    margin-right: 15px;
    transition: color 0.3s ease;
}

.pdfLink:hover {
    color: #368124;
    text-decoration: underline;
}

.nextStepBtn {
    background-color: #4eb933;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.nextStepBtn:hover {
    background-color: #3aa72a;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.assuranceCount {
    margin-left: 10px;
    font-size: 1.4rem;
    background-color: #6d6d6d;
    color: #fff;
    padding: 5px 15px;
    border-radius: 20px;
}

.alreadyClientBtn {
  background-color: #4eb933;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 12px; /* Taille de la police */
  border-radius: 5px; /* Coins arrondis */
  height: 45px; /* Alignement avec le champ email */
  cursor: pointer;
  display: flex;
  align-items: center; /* Centre verticalement le texte */
  justify-content: center; /* Centre horizontalement le texte */
  transition: all 0.3s ease; /* Effet fluide au survol */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre subtile */
}

.alreadyClientBtn:hover {
  background-color: #3aa72a; /* Couleur plus foncée au survol */
  transform: translateY(-2px); /* Légère remontée au survol */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Ombre plus marquée */
}

