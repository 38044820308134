/* VendorLogin.module.css */

/* Conteneur principal avec l'image de fond */
.container {
    background: url('../../../assets/images/vendor/accueil.jpg') no-repeat center center fixed;
    background-size: cover; /* Adapte l'image pour couvrir toute la zone */
    padding: 20px;
    height: 100vh; /* Assure que le conteneur couvre la hauteur de la fenêtre */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Carte de connexion */
.card {
    max-width: 500px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9); /* Légère transparence pour voir l'image en arrière-plan */
    border-radius: 10px;
    padding: 50px 40px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
}

.card:hover {
    transform: scale(1.02);
}

/* Titre de la carte */
.title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
}

/* Style des champs de saisie */
.input {
    border-radius: 6px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    transition: all 0.3s ease;
}

.input:focus {
    border-color: #4eb933;
    box-shadow: 0 4px 8px rgba(78, 185, 51, 0.1);
    outline: none;
}

/* Bouton de connexion */
.button {
    background-color: #4eb933;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    padding: 15px;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
}

.button:hover {
    background-color: #3aa72a;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Style des liens */
.links {
    font-size: 0.9rem;
}

.link {
    color: #4eb933;
    text-decoration: none;
    transition: color 0.2s ease;
}

.link:hover {
    color: #3aa72a;
    text-decoration: underline;
}

/* Sélecteur de langue avec drapeaux */
.languageSelector {
    display: flex;
    gap: 15px;
    cursor: pointer;
}

.flagIcon {
    width: 30px;
    height: 20px;
    transition: transform 0.2s ease;
}

.flagIcon:hover {
    transform: scale(1.1);
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.popup {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
}

/* Titre de la popup */
.popup h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
}

/* Message de la popup */
.popup p {
    font-size: 1rem;
    color: #555;
    margin: 20px 0;
}

/* Bouton de fermeture de la popup */
.closeButton {
    background-color: #4eb933;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.closeButton:hover {
    background-color: #3aa72a;
}