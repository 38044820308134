/* VendeurAccueil.module.css */

.accueilPage {
    background-color: #ffffff;
    overflow: hidden; /* Évite les débordements */
    margin: 0;
    padding: 0;
    min-height: 100vh; /* Assure que l'élément couvre toute la hauteur de la page */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.accueilContainer {
    width: 100vw;
    min-height: calc(100vh - 60px); /* Assure que le fond prend au moins toute la hauteur */
    background: #ffffff url('../../../assets/images/vendor/login.png') no-repeat center top/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 60px;
    background-attachment: fixed; /* Fixe le fond pour qu'il s'étire mieux */
}


.overlay {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.8);
    max-width: 700px;
    width: 80%;
    z-index: 2;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-20px);
}

.welcomeText {
    font-size: 2.5rem;
    color: #000000;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.buttonGroup {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.largeBtn, .mediumBtn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 0.5rem;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
    padding: 1rem 0;
}

.largeBtn {
    background-color: #44a02c;
    color: #000000;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.largeBtn:hover {
    background-color: #44a02c;
    color: #ffffff;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
}

.mediumBtn {
    background-color:#ffffff00;
    color: #000000;
    border: 2px solid #4eb933b8;
}

.mediumBtn:hover {
    background-color: #44a02c;
    color: #ffffff;
}

/* Responsive design */

/* Écrans larges */
@media (max-width: 1600px) {
    .welcomeText {
        font-size: 2rem;
    }

    .buttonGroup .largeBtn, .buttonGroup .mediumBtn {
        font-size: 1.4rem;
        padding: 0.8rem;
    }
}

/* Tablettes */
@media (max-width: 1300px) {
    .welcomeText {
        font-size: 1.75rem;
    }

    .largeBtn, .mediumBtn {
        font-size: 1.3rem;
    }
}

/* Mobiles */
@media (max-width: 800px) {
    .accueilContainer {
        align-items: center;
        background-position: center top;
    }

    .welcomeText {
        font-size: 1.5rem;
    }

    .buttonGroup {
        align-items: center;
    }

    .largeBtn, .mediumBtn {
        font-size: 1.1rem;
        width: 90%;
    }
}
